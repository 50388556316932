import React, { useState } from 'react';
import { VscGame } from 'react-icons/vsc';
import { ReactComponent as PatternLeft } from './img/pattern-left.svg';
import { ReactComponent as PatternRight } from './img/pattern-right.svg';
import doneMedal from '../../images/doneMedal.svg';
import ChallengeModal from './ChallengeModal';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';

export default function ChallengeCard({ challenge }) {
  const { setCurrentChallenge, setCurrentStep, setRightAnswers, setCurrentRound, setStart, setGeneralCount, setShowQuestionCountDown, setQuestionCountDownTimer } = useWeeklyChallenge();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setGeneralCount(0);
    setShowModal(false);
    setRightAnswers(0);
    setCurrentRound(1);
    setStart(false);
    setShowQuestionCountDown(false);
    setQuestionCountDownTimer(5);
    setTimeout(() => setCurrentStep(1), 300);
  };

  const handleStart = async () => {
    setCurrentChallenge(challenge);
    setShowModal(true);
  };

  return (
    <div className={challenge?.done ? 'challenge-card challenge-card--done' : 'challenge-card'}>
      <img
        className="challenge-card__done-medal"
        src={doneMedal}
        alt="concluído"
      />

      <span className="challenge-card__tag">{challenge?.taxonomy?.name}</span>

      <img
        className="avatar avatar--lg"
        src={challenge?.image}
        alt="foto"
      />

      <div className="challenge-card__body">
        <p className="challenge-card__category">
          <span className="challenge-card__icon challenge-card__icon--square">
            <VscGame />
          </span>
        </p>
        <div className="challenge-card__text">
          <h3
            className="challenge-card__title"
            title={challenge?.title}
          >
            {challenge?.title}
          </h3>
          <p
            className="challenge-card__name"
            title={challenge?.user?.name}
          >
            {challenge?.user?.name}
          </p>
          <p title={challenge?.user?.institution?.regional?.name}>{challenge?.user?.institution?.regional?.name}</p>
        </div>

        <div className="challenge-card__banner">
          <PatternLeft className="challenge-card__pattern" />
          <p className="challenge-card__banner-text">Ganhe {challenge?.point} Pontos</p>
          <PatternRight className="challenge-card__pattern" />
        </div>
      </div>

      <div className="challenge-card__footer">
        {!challenge?.done ? (
          <button
            className="btn btn--small btn--review btn--full"
            onClick={handleStart}
          >
            <VscGame className="btn__icon" />
            Começar desafio
          </button>
        ) : (
          <button className="btn btn--small btn--success btn--full">Desafio concluído</button>
        )}
      </div>

      <ChallengeModal
        show={showModal}
        onClose={handleCloseModal}
      />
    </div>
  );
}
