import React, { useEffect, useState } from 'react';

export function GeoChart({ regionals = [] }) {
  const [selectedPathId, setSelectedPathId] = useState(null);

  const [hoveredRegional, setHoveredRegional] = useState(null);

  //tarefa de deixar o grafico o mais dinamico possivel
  const maximusAccessCount = Math.max(...regionals.map(region => region.accessCount));
  const divisor = Math.ceil(maximusAccessCount / 5);

  const handleMouseEnter = id => {
    setHoveredRegional(id);
  };

  const handleMouseLeave = () => {
    setHoveredRegional(null);
  };

  const handleClick = id => {
    setSelectedPathId(id);
  };

  // function hexToRgb(hex) {
  //   var bigint = parseInt(hex.slice(1), 16);
  //   var r = (bigint >> 16) & 255;
  //   var g = (bigint >> 8) & 255;
  //   var b = bigint & 255;

  //   return [r, g, b];
  // }

  // function rgbToHex(r, g, b) {
  //   return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  // }

  // function interpolateColor(color1, color2, factor) {
  //   if (arguments.length < 3) {
  //     factor = 0.5;
  //   }
  //   var result = color1.slice();
  //   for (var i = 0; i < 3; i++) {
  //     result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  //   }
  //   return result;
  // }

  // function getColorAtPercentage(pct) {
  //   pct = Math.min(pct, 100);
  //   const color1 = pct <= 50 ? hexToRgb('#351494') : hexToRgb('#FF0000');
  //   const color2 = pct <= 50 ? hexToRgb('#FF0000') : hexToRgb('#FFEB33');

  //   const factor = pct <= 50 ? pct / 50 : (pct - 50) / 50;

  //   const rgbColor = interpolateColor(color1, color2, factor);

  //   return rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2]);
  // }

  const getColorByAccess = count => {
    if (count < divisor) return '#482B8A';
    if (count < divisor * 2) return '#9D174A';
    if (count < divisor * 3) return '#E80612';
    if (count < divisor * 4) return '#FE7E45';
    return '#FFE045';
  };

  const regionalsOrderById = regionals.sort((a, b) => a.id - b.id);

  const maxAccessCount = Math.max(...regionalsOrderById?.map(regional => regional?.accessCount));
  const minAccessCount = Math.min(...regionalsOrderById.map(regional => regional.accessCount));
  const middleValues = regionalsOrderById.map(regional => regional.accessCount).filter(accessCount => accessCount > minAccessCount && accessCount < maxAccessCount);
  middleValues.sort((a, b) => a - b);
  const middleAccessCount = middleValues[Math.floor(middleValues?.length / 2)];

  const semVinculo = regionalsOrderById.filter(regional => regional.name.toLowerCase() === 'sem vinculo');
  const jaSouFormado = regionalsOrderById.filter(regional => regional.name.toLowerCase() === 'já sou formado');

  const semVinculoColor = getColorByAccess(semVinculo[0]?.accessCount);
  const jaSouFormadoColor = getColorByAccess(jaSouFormado[0]?.accessCount);

  const labels = [];

  if (divisor > 0) {
    for (let i = 0; i <= maxAccessCount; i += divisor) {
      labels?.push(i);
    }
  }

  function AccessBar() {
    // if (!middleAccessCount || !maxAccessCount) return <></>;

    return (
      <div
        style={{
          height: 318,
          display: 'flex'
        }}
      >
        <div
          style={{
            background: `linear-gradient(rgba(53, 20, 148, 1), rgba(255, 0, 0, 1), rgba(255, 235, 51, 1))`,
            width: 8
          }}
        ></div>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingLeft: 15 }}>
          {labels?.map((label, index) => (
            <span key={index}>{label}</span>
          ))}
        </div>
      </div>
    );
  }

  const svgPathList = [
    {
      name: '1ª GRE',
      id: regionalsOrderById?.[0]?.id,
      accessCount: regionalsOrderById?.[0]?.accessCount,
      d: 'M418.078 14.243L377.406 0.32891C376.877 0.148116 376.303 0.435269 376.131 0.96657L363.267 40.6302C363.152 40.9854 362.849 41.2468 362.48 41.3081L352.44 42.9815C351.518 43.1353 351.292 44.3586 352.1 44.831L366.399 53.1974C366.68 53.3616 366.863 53.6521 366.891 53.9762L369 78.879C369.034 79.2881 369.315 79.6345 369.709 79.7524L374.29 81.1269C374.48 81.1839 374.683 81.1831 374.873 81.1247L402.808 72.5058C403.044 72.4331 403.298 72.45 403.522 72.5534L416.26 78.4326C416.916 78.7353 417.667 78.2641 417.679 77.5419L418.754 15.2064C418.761 14.7725 418.488 14.3834 418.078 14.243Z',
      left: 407,
      top: 28
    },
    {
      name: '2ª GRE',
      id: regionalsOrderById?.[1]?.id,
      accessCount: regionalsOrderById?.[1]?.accessCount,
      d: 'M339.32 57.9148H318.027C317.651 57.9148 317.307 58.1252 317.136 58.4595L294.646 102.431C294.521 102.676 294.177 102.695 294.026 102.464L314.927 134.366C315.092 134.617 315.135 134.929 315.045 135.215L313.567 139.894C313.397 140.434 313.708 141.008 314.254 141.159L338.487 147.862C338.921 147.982 339.382 147.797 339.612 147.41L363.377 107.536C363.477 107.368 363.526 107.174 363.517 106.978L361.346 59.6663C361.33 59.3237 361.14 59.0131 360.842 58.8433L348.097 51.5809C347.73 51.3719 347.272 51.4157 346.951 51.6905L339.97 57.6741C339.789 57.8294 339.558 57.9148 339.32 57.9148Z',
      left: 267,
      top: 62
    },
    {
      name: '3ª GRE',
      id: regionalsOrderById?.[2]?.id,
      accessCount: regionalsOrderById?.[2]?.accessCount,
      d: 'M440.923 140.676L430.067 173.243C429.963 173.554 429.713 173.795 429.397 173.887L395.864 183.622C395.557 183.711 395.226 183.648 394.973 183.453L361.093 157.223C360.934 157.1 360.816 156.933 360.754 156.742L354.823 138.525C354.739 138.265 354.764 137.983 354.893 137.743L371.114 107.462C371.213 107.278 371.251 107.069 371.225 106.862L368.695 87.2384C368.603 86.5319 369.259 85.9595 369.947 86.1451L375.926 87.7591C376.129 87.8139 376.344 87.8034 376.54 87.7292L400.759 78.5798C400.979 78.4967 401.221 78.4938 401.443 78.5716L418.335 84.4914C418.607 84.5869 418.825 84.7956 418.933 85.0637L440.903 139.988C440.99 140.208 440.998 140.452 440.923 140.676Z',
      left: 423,
      top: 105
    },
    {
      name: '4ª GRE',
      id: regionalsOrderById?.[3]?.id,
      accessCount: regionalsOrderById?.[3]?.accessCount,
      d: 'M301.213 168.762L291.002 180.012C290.756 180.282 290.678 180.665 290.797 181.011L301.684 212.494C301.838 212.939 302.281 213.216 302.749 213.16L314.032 211.8C314.535 211.74 314.913 211.313 314.913 210.807V182.403C314.913 182.152 314.818 181.91 314.648 181.726L302.689 168.757C302.291 168.325 301.608 168.328 301.213 168.762Z',
      left: 255,
      top: 170
    },
    {
      name: '5ª GRE',
      id: regionalsOrderById?.[4]?.id,
      accessCount: regionalsOrderById?.[4]?.accessCount,
      d: 'M448.846 234.009L453.504 288.621C453.549 289.153 453.17 289.626 452.641 289.697L405.39 296.071C405.12 296.107 404.846 296.032 404.633 295.861L351.249 253.263C350.748 252.863 350.748 252.101 351.248 251.7L363.505 241.895C363.848 241.62 363.972 241.153 363.809 240.744L334.973 168.383C334.865 168.11 334.881 167.803 335.019 167.544L347.506 144.069C347.92 143.29 349.068 143.391 349.34 144.231L354.593 160.469C354.657 160.665 354.78 160.837 354.945 160.961L394.06 190.297C394.317 190.49 394.651 190.548 394.958 190.452L428.905 179.843C429.42 179.682 429.969 179.959 430.147 180.468L448.793 233.764C448.821 233.843 448.839 233.926 448.846 234.009Z',
      left: 434,
      top: 209
    },
    {
      name: '6ª GRE',
      id: regionalsOrderById?.[5]?.id,
      accessCount: regionalsOrderById?.[5]?.accessCount,
      d: 'M269.22 269.956L262.958 308.892C262.889 309.323 263.108 309.75 263.498 309.945L273.333 314.862C273.719 315.055 273.937 315.474 273.875 315.901L269.689 344.749C269.64 345.09 269.77 345.433 270.033 345.656L290.541 363.009C290.9 363.313 291.423 363.325 291.796 363.038L320.411 341.067C320.631 340.898 320.771 340.645 320.797 340.368L323.17 315.503C323.19 315.296 323.145 315.089 323.041 314.909L311.598 295.021L297.649 270.026C297.475 269.714 297.148 269.519 296.791 269.514L270.222 269.115C269.726 269.107 269.299 269.465 269.22 269.956Z',
      left: 270,
      top: 308
    },
    {
      name: '7ª GRE',
      id: regionalsOrderById?.[6]?.id,
      accessCount: regionalsOrderById?.[6]?.accessCount,
      d: 'M460.765 330.339L456.211 294.334C456.16 293.928 455.686 293.734 455.365 293.987C455.294 294.042 455.211 294.079 455.123 294.093L404.587 302.183C404.315 302.227 404.038 302.157 403.82 301.99L352.931 263.01C352.53 262.703 351.963 262.743 351.609 263.103L321.804 293.441C321.498 293.752 321.429 294.226 321.634 294.611L330.55 311.395C330.636 311.557 330.676 311.741 330.665 311.924L329.621 329.147C329.595 329.581 329.852 329.982 330.257 330.139L368.616 345.057C369.049 345.225 369.541 345.073 369.804 344.689L373.626 339.102C373.829 338.805 374.177 338.64 374.536 338.67L413.906 342.013C414.038 342.024 414.167 342.061 414.285 342.123L436.787 353.873C437.174 354.075 437.648 354.003 437.957 353.694L460.48 331.171C460.698 330.953 460.803 330.645 460.765 330.339Z',
      left: 443,
      top: 305
    },
    {
      name: '8ª GRE',
      id: regionalsOrderById?.[7]?.id,
      accessCount: regionalsOrderById?.[7]?.accessCount,
      d: 'M332.577 338.945L298.614 368.339C298.169 368.724 298.151 369.407 298.574 369.815L328.23 398.413C328.528 398.7 328.619 399.141 328.457 399.522L317.158 426.229C316.879 426.888 317.363 427.618 318.079 427.618H377.472C378.311 427.618 378.777 426.648 378.253 425.994L357.678 400.274C357.533 400.093 357.456 399.868 357.459 399.636L358.001 361.111C358.005 360.889 358.082 360.674 358.221 360.501L365.947 350.843C366.382 350.298 366.141 349.487 365.479 349.268L333.545 338.752C333.211 338.642 332.843 338.715 332.577 338.945Z',
      left: 312,
      top: 363
    },
    {
      name: '9ª GRE',
      id: regionalsOrderById?.[8]?.id,
      accessCount: regionalsOrderById?.[8]?.accessCount,
      d: 'M412.492 349.708L374.829 347.524C374.452 347.502 374.095 347.694 373.906 348.021L365.323 362.848C365.235 363 365.188 363.173 365.188 363.349V396.868C365.188 397.104 365.272 397.333 365.424 397.513L387.132 423.223C387.401 423.541 387.84 423.659 388.233 423.519L436.449 406.274C436.795 406.15 437.045 405.847 437.101 405.484L443.157 366.022C443.222 365.596 443.007 365.176 442.623 364.98L412.889 349.815C412.765 349.752 412.63 349.716 412.492 349.708Z',
      left: 379,
      top: 369
    },
    {
      name: '10ª GRE',
      id: regionalsOrderById?.[9]?.id,
      accessCount: regionalsOrderById?.[9]?.accessCount,
      d: 'M177.741 379.093L169.133 368.646C168.682 368.099 168.928 367.269 169.604 367.056L200.303 357.382C200.465 357.33 200.638 357.322 200.805 357.356L231.235 363.614C231.406 363.649 231.584 363.639 231.751 363.584L266.145 352.188C266.494 352.072 266.878 352.157 267.146 352.41L318.512 400.862C318.807 401.141 318.905 401.57 318.759 401.949L309.69 425.54C309.519 425.984 309.058 426.247 308.589 426.167L297.319 424.252C296.886 424.178 296.456 424.396 296.258 424.788L282.868 451.408C282.771 451.601 282.613 451.758 282.42 451.854L250.584 467.678C250.197 467.87 249.73 467.792 249.427 467.484L194.565 411.822C194.308 411.562 193.929 411.462 193.577 411.563L175.936 416.62C175.793 416.661 175.662 416.733 175.55 416.831L165.676 425.534C165.216 425.939 164.504 425.839 164.173 425.324L160.296 419.286C160.112 418.998 160.087 418.636 160.23 418.326L177.877 380.149C178.038 379.8 177.986 379.39 177.741 379.093Z',
      left: 223,
      top: 385
    },
    {
      name: '11ª GRE',
      id: regionalsOrderById?.[10]?.id,
      accessCount: regionalsOrderById?.[10]?.accessCount,
      d: 'M51.0869 438.141L31.3909 498.324C31.2658 498.706 31.3817 499.125 31.6851 499.389L56.47 520.941C56.6807 521.125 56.8052 521.387 56.8134 521.667L57.4474 543.258C57.4546 543.503 57.3714 543.743 57.2136 543.93L46.2667 556.962C46.0165 557.26 45.9617 557.676 46.1262 558.029L49.5512 565.368C49.6619 565.605 49.8612 565.789 50.1063 565.881L62.3807 570.484C62.7478 570.622 63.1614 570.532 63.4387 570.255L94.4476 539.268C94.5909 539.125 94.6873 538.942 94.7241 538.743L104.751 484.552C104.866 483.935 105.514 483.574 106.099 483.803L144.143 498.702C144.53 498.854 144.97 498.75 145.249 498.442L202.684 434.944C203.038 434.552 203.027 433.954 202.659 433.576L189.769 420.324C189.503 420.052 189.106 419.953 188.744 420.071L175.904 424.234C175.763 424.279 175.633 424.356 175.525 424.459L161.239 437.996C160.789 438.422 160.064 438.341 159.72 437.825L147.343 419.26C147.14 418.955 147.119 418.563 147.29 418.239L167.627 379.622C167.823 379.249 167.765 378.793 167.48 378.481L161.014 371.408C160.657 371.018 160.057 370.973 159.646 371.306L103.984 416.443C103.906 416.506 103.82 416.556 103.727 416.594L51.6642 437.525C51.3901 437.635 51.1788 437.861 51.0869 438.141Z',
      left: 74,
      top: 439
    },
    {
      name: '12ª GRE',
      id: regionalsOrderById?.[11]?.id,
      accessCount: regionalsOrderById?.[11]?.accessCount,
      d: 'M223.814 494.858L227.037 515.703C227.126 516.279 227.687 516.657 228.254 516.524L246.716 512.203C246.875 512.166 247.04 512.168 247.198 512.209L300.831 526.295C301.305 526.419 301.619 526.869 301.573 527.357L300.692 536.604C300.624 537.319 301.308 537.87 301.992 537.652L353.816 521.099C354.043 521.026 354.289 521.037 354.509 521.13L394.044 537.839C394.188 537.899 394.344 537.926 394.5 537.915L409.195 536.936C409.973 536.884 410.395 536.001 409.947 535.363L381.372 494.698C381.266 494.547 381.204 494.37 381.192 494.187L377.403 434.645C377.37 434.113 376.924 433.701 376.391 433.709L301.676 434.791C301.32 434.796 300.994 434.99 300.819 435.301L286.336 461.049C286.237 461.224 286.087 461.366 285.906 461.455L247.647 480.316C247.24 480.517 246.75 480.419 246.452 480.078L240.074 472.788C239.643 472.296 238.864 472.342 238.495 472.882L223.977 494.141C223.834 494.351 223.775 494.607 223.814 494.858Z',
      left: 303,
      top: 476
    },
    {
      name: '13ª GRE',
      id: regionalsOrderById?.[12]?.id,
      accessCount: regionalsOrderById?.[12]?.accessCount,
      d: 'M209.012 587.479L197.478 569.653C197.268 569.328 197.263 568.912 197.467 568.583L224.645 524.65C224.783 524.427 225.003 524.267 225.258 524.205L247.272 518.836C247.43 518.797 247.594 518.798 247.751 518.837L295.288 530.721C295.771 530.842 296.092 531.3 296.04 531.795L294.628 545.357C294.554 546.066 295.225 546.622 295.908 546.418L354.874 528.833C355.113 528.761 355.37 528.782 355.594 528.89L390.398 545.634C390.534 545.699 390.682 545.733 390.832 545.733H408.24C409.18 545.733 409.6 546.913 408.872 547.507L390.604 562.423L369.993 578.961C369.843 579.081 369.661 579.155 369.47 579.175L337.682 582.464C337.539 582.478 337.4 582.524 337.276 582.597L289.149 611.036C288.746 611.274 288.232 611.201 287.912 610.861L270.828 592.709C270.705 592.578 270.55 592.483 270.378 592.434L236.354 582.556C236.197 582.51 236.031 582.504 235.871 582.537L210.056 587.915C209.652 587.999 209.237 587.826 209.012 587.479Z',
      left: 317,
      top: 561
    },
    {
      name: '14ª GRE',
      id: regionalsOrderById?.[13]?.id,
      accessCount: regionalsOrderById?.[13]?.accessCount,
      d: 'M151.034 614.574L70.3459 575.32C69.7188 575.015 69.5887 574.178 70.0938 573.697L101.413 543.869C101.57 543.72 101.674 543.524 101.71 543.311L109.738 495.679C109.843 495.054 110.495 494.683 111.086 494.912L146.291 508.574C146.69 508.729 147.143 508.613 147.418 508.286L204.797 440.081C205.182 439.623 205.881 439.604 206.291 440.04L230.938 466.26C231.259 466.602 231.3 467.12 231.036 467.508L215.192 490.805C215.038 491.032 214.983 491.312 215.041 491.58L220.431 516.373C220.487 516.631 220.439 516.901 220.297 517.123L187.44 568.6C187.236 568.919 187.23 569.327 187.425 569.652L199.906 590.453C200.209 590.959 200.011 591.616 199.479 591.87L151.902 614.577C151.627 614.708 151.308 614.707 151.034 614.574Z',
      left: 129,
      top: 532
    },
    {
      name: '15ª GRE',
      id: regionalsOrderById?.[14]?.id,
      accessCount: regionalsOrderById?.[14]?.accessCount,
      d: 'M49.7743 525.366L25.4473 504.212C24.9632 503.791 24.2134 503.929 23.91 504.494L0.137169 548.798C0.0475703 548.965 0.00726082 549.154 0.0209371 549.343L5.49386 624.979C5.51361 625.252 5.64432 625.505 5.85554 625.679L34.3927 649.18C34.4808 649.253 34.5558 649.34 34.6145 649.438L51.1805 677.048C51.2147 677.105 51.2544 677.158 51.2991 677.207L85.2305 714.422C85.5402 714.762 86.0415 714.847 86.4457 714.628L138.759 686.291C139.046 686.136 139.391 686.13 139.683 686.276L151.871 692.371C152.287 692.579 152.792 692.472 153.089 692.114L189.32 648.394C189.438 648.252 189.514 648.08 189.54 647.897L195.751 604.379C195.865 603.585 195.044 602.987 194.323 603.339L153.017 623.462C152.742 623.596 152.421 623.597 152.146 623.464L46.1516 572.511C45.9594 572.418 45.8018 572.267 45.7017 572.079L36.8085 555.338C36.6033 554.952 36.673 554.477 36.9805 554.166L49.8293 541.169C50.0143 540.982 50.1181 540.73 50.1181 540.466V526.121C50.1181 525.832 49.9927 525.556 49.7743 525.366Z',
      left: 65,
      top: 630
    },
    {
      name: '16ª GRE',
      id: regionalsOrderById?.[15]?.id,
      accessCount: regionalsOrderById?.[15]?.accessCount,
      d: 'M496.201 361.588L461.784 336.85C461.384 336.562 460.832 336.61 460.487 336.962L442.167 355.645C441.767 356.054 441.79 356.715 442.219 357.094L447.347 361.633C447.583 361.842 447.707 362.151 447.68 362.466L443.681 409.468C443.655 409.766 443.765 410.06 443.98 410.269L463.406 429.17C463.786 429.54 463.811 430.141 463.464 430.542L457.478 437.448C457.148 437.83 457.153 438.397 457.491 438.772L461.623 443.363C461.784 443.542 462.004 443.657 462.242 443.687L478.634 445.736C479.152 445.8 479.633 445.456 479.737 444.944L496.597 362.6C496.676 362.214 496.521 361.818 496.201 361.588Z',
      left: 470,
      top: 363
    },
    {
      name: '17ª GRE',
      id: regionalsOrderById?.[16]?.id,
      accessCount: regionalsOrderById?.[16]?.accessCount,
      d: 'M390.573 492.534L384.604 436.095C384.557 435.655 384.805 435.236 385.214 435.066L436.945 413.511C437.319 413.356 437.75 413.441 438.037 413.727L454.588 430.278C454.957 430.648 454.98 431.24 454.64 431.636L449.199 437.985C448.866 438.373 448.88 438.95 449.231 439.322L457.787 448.382C457.929 448.532 458.114 448.635 458.316 448.675L480.29 453.121C480.775 453.219 481.116 453.657 481.091 454.151L480.039 475.195C480.027 475.434 479.929 475.661 479.764 475.835L458.911 497.729C458.877 497.765 458.839 497.799 458.8 497.829L419.026 528.655C418.586 528.996 417.952 528.912 417.616 528.469L390.771 493.033C390.661 492.888 390.593 492.715 390.573 492.534Z',
      left: 453,
      top: 477
    },
    {
      name: '18ª GRE',
      id: regionalsOrderById?.[17]?.id,
      accessCount: regionalsOrderById?.[17]?.accessCount,
      d: 'M309.374 134.96L292.53 109.525C292.137 108.933 291.27 108.927 290.87 109.514L271.961 137.221C271.82 137.427 271.761 137.679 271.797 137.926L279.415 191.256C279.486 191.749 279.908 192.115 280.405 192.115H294.41C294.84 192.115 295.222 192.39 295.358 192.798L302.927 215.503L303.92 219.478C303.996 219.78 303.926 220.101 303.731 220.345L299.796 225.263C299.659 225.434 299.47 225.555 299.258 225.609L286.326 228.842C286.256 228.859 286.188 228.884 286.123 228.916L240.637 251.502C240.156 251.741 239.949 252.317 240.169 252.807L253.922 283.441L259.058 296.28C259.448 297.256 260.887 297.046 260.982 295.999L263.752 265.565C263.797 265.061 264.213 264.671 264.718 264.656L301.348 263.595C301.72 263.584 302.067 263.78 302.249 264.105L316.481 289.46C316.804 290.036 317.586 290.146 318.055 289.683L346.232 261.881C346.63 261.488 346.628 260.846 346.229 260.454L340.575 254.924C340.148 254.506 340.18 253.809 340.644 253.433L356.889 240.233C357.226 239.96 357.348 239.501 357.191 239.097L329.444 167.28C329.338 167.006 329.358 166.699 329.499 166.441L335.331 155.748C335.631 155.199 335.359 154.514 334.765 154.319L308.243 145.62C307.754 145.46 307.466 144.955 307.578 144.453L309.517 135.729C309.576 135.464 309.524 135.186 309.374 134.96Z',
      left: 235,
      top: 118
    },
    {
      name: '19ª GRE',
      id: regionalsOrderById?.[18]?.id,
      accessCount: regionalsOrderById?.[18]?.accessCount,
      d: 'M303.501 219.779L302.29 214.867C302.147 214.287 302.542 213.711 303.135 213.636L314.129 212.236C314.529 212.186 314.921 212.38 315.122 212.73L325.438 230.683C325.723 231.179 325.534 231.813 325.024 232.073L316.801 236.251C316.538 236.384 316.229 236.395 315.958 236.28L298.44 228.866C297.833 228.609 297.636 227.844 298.044 227.326L303.315 220.638C303.506 220.395 303.574 220.079 303.501 219.779Z',
      left: 261,
      top: 220
    },

    {
      name: '20ª GRE',
      id: regionalsOrderById?.[19]?.id,
      accessCount: regionalsOrderById?.[19]?.accessCount,
      d: 'M328.539 181.213H315.968C315.395 181.213 314.939 181.694 314.97 182.267L316.422 209.218C316.464 210.008 317.364 210.438 318.006 209.975L332.084 199.812C332.395 199.587 332.55 199.205 332.484 198.827L329.524 182.039C329.439 181.561 329.024 181.213 328.539 181.213Z',
      left: 322,
      top: 174
    },

    {
      name: '21ª GRE',
      id: regionalsOrderById?.[20]?.id,
      accessCount: regionalsOrderById?.[20]?.accessCount,
      d: 'M332.592 227.727V205.041C332.592 204.272 331.76 203.791 331.093 204.174L316.85 212.364C316.385 212.632 316.213 213.218 316.462 213.694L324.024 228.189C324.197 228.519 324.538 228.727 324.911 228.727H331.592C332.144 228.727 332.592 228.279 332.592 227.727Z',
      left: 327,
      top: 208
    }
  ];

  return (
    <section style={{ display: 'flex' }}>
      <div>
        <span>Acessos Únicos</span>

        <AccessBar />
      </div>

      <div className="svg-container">
        <div className="svg-container__holder">
          <svg
            width="530"
            height="715"
            viewBox="0 0 530 715"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {svgPathList.map(
              item =>
                (selectedPathId === null || selectedPathId === item.id) && (
                  <path
                    key={item.id}
                    d={item.d}
                    fill={getColorByAccess(item.accessCount)}
                    stroke="white"
                    stroke-width="2"
                    stroke-linejoin="round"
                    onMouseEnter={() => handleMouseEnter(item.id)}
                    onMouseLeave={handleMouseLeave}
                    style={{ cursor: 'pointer' }}
                    // onClick={() => handleClick(item.id)}
                  />
                )
            )}
          </svg>
          {svgPathList?.map(item => (
            <div
              className="GRE-item"
              style={{
                left: item.left,
                top: item.top,
                display: hoveredRegional === item.id ? 'block' : 'none'
              }}
              key={item.id}
            >
              <span>{item.name}</span>
              <span> - {item.accessCount}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="report-widget__legend-wrapper">
        {jaSouFormado?.length > 0 && (
          <div className="report-widget__legend">
            <h3 className="report-widget__legend-title">Já sou formado</h3>
            <p className="report-widget__legend-count">
              {jaSouFormado[0]?.accessCount} {jaSouFormado[0]?.accessCount === 1 ? 'acesso' : 'acessos'}
            </p>
          </div>
        )}

        {semVinculo?.length > 0 && (
          <div className="report-widget__legend">
            <h3 className="report-widget__legend-title">Alunos sem vínculo</h3>
            <p className="report-widget__legend-count">
              {semVinculo[0]?.accessCount} {semVinculo[0]?.accessCount === 1 ? 'acesso' : 'acessos'}
            </p>
          </div>
        )}
      </div>
    </section>
  );
}
