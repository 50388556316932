import React, { useEffect, useState } from 'react';
import { Link, navigate, useParams } from '@reach/router';
import { FiChevronLeft, FiChevronDown } from 'react-icons/fi';
import { useFormik } from 'formik';
import CourseCover from 'app/images/placeholders/show-course.jpg';
import StarRating from 'app/components/StarRating';
import { AvatarStack } from 'app/components/Avatar';
import InfoWidget from './InfoWidget';
import ModuleCard from './ModuleCard';
import { useCourse } from 'app/hooks/useCourse';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { t } from 'i18next';
import getPermission from 'app/utils/getPermission';
import Modal from 'app/components/Modal';
import ViewModule from './ViewModule';
import BreadCrumbs from 'app/components/BreadCrumbs';
import IconVerified from 'app/components/Icons/IconVerified';
import { useSession } from 'app/hooks/useSession';

export default function CourseAboutAdmin(props) {
  const { uri } = props;

  const { getDetailedCourses, detailedCourse, loading, managers, students } = useCourse();

  const [showModal, setShowModal] = useState(false);
  const [showModule, setShowModule] = useState(false);
  const [currentModule, setCurrentModule] = useState({});

  const currentUser = useSession()?.session?.user;

  const courseId = useParams().id;
  const filters = useFormik({
    initialValues: {
      searchTerm: ''
    }
  });

  useEffect(() => getDetailedCourses(courseId), []);

  const filteredModules = detailedCourse?.['learning-systems']?.filter(item => {
    if (filters.values.searchTerm === '') return true;
    if (filters.values.searchTerm !== '' && item.title.toLowerCase().includes(filters.values.searchTerm.toLowerCase())) return true;
    return false;
  });

  const modulesAuthoredByUser = detailedCourse?.['learning-systems'].filter(item => item?.['creator_id'] === currentUser.id || !item?.['authored_by_user']);

  const getSelectedModule = () => {
    if (modulesAuthoredByUser.length <= 0 && currentUser?.profileNames.includes('Administrador')) {
      return detailedCourse?.['learning-systems'][0].id;
    } else if (modulesAuthoredByUser.length > 0) {
      return modulesAuthoredByUser?.[0].id;
    }
  };

  const breadCrumbs = {
    title: 'Cursos e Aulas',
    nav: [
      {
        route: '/curso/admin',
        name: 'Cursos',
        isActive: false
      },
      {
        route: uri,
        name: detailedCourse?.title,
        'official-content': detailedCourse?.['official-content'] ?? false,
        isActive: true
      }
    ]
  };

  return (
    <div className="main-content main-content--block">
      {!detailedCourse && loading ? (
        <Loader />
      ) : (
        <>
          <BreadCrumbs data={breadCrumbs} />

          <div className="filter-bar filter-bar--borderless">
            <div className="filter-bar__inner" />
            <div className="filter-bar__actions">
              {getPermission('Gerenciar conteúdo', 'Cursos, módulos e aulas') &&
                (!detailedCourse?.['authored-by-user'] || (detailedCourse?.['authored-by-user'] && currentUser.id === detailedCourse?.['creator-id']) || currentUser?.profileNames.includes('Administrador')) && (
                  <Link
                    className="btn btn--wide btn--outline"
                    to={`/curso/admin/gerenciar/${detailedCourse?.id}/${detailedCourse?.['learning-systems'].length > 0 ? getSelectedModule() : 'm'}/disciplinas-e-aulas/d`}
                  >
                    {t('button.manageContent')}
                  </Link>
                )}
            </div>
          </div>

          <div className="course-about u-mb-4">
            <div
              className="show-course-banner"
              style={{
                backgroundImage: `url('${detailedCourse?.image?.url ?? CourseCover}')`
              }}
            >
              <div className="show-course-banner__inner">
                <div className="show-course-banner__container">
                  <div className="show-course-banner__title">{detailedCourse?.title}</div>
                  {/* <div className="show-course-banner__description">{detailedCourse?.about}</div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="course-about u-mb-4">
            <div className="course-about__header course-about__header--borderless">
              <div className="course-about__container-header u-mb-0">
                <div className="course-about__inner-header">
                  <div className="course-about__infos">
                    <div className="course-about__rating">
                      <StarRating
                        staticRating
                        rating={parseInt(detailedCourse?.rating)}
                      />
                      <p className="course-about__rating-text">
                        <strong>{parseFloat(detailedCourse?.rating).toFixed(2)}</strong> ({detailedCourse?.['total-reviews']} {t('courses.evaluations')})
                      </p>
                    </div>
                  </div>
                </div>

                <div className="course-about__participants">
                  <div className="card__avatar">
                    <h4 className="card__avatar-title">
                      {t('courses.managers')} ({managers?.length})
                    </h4>
                    {managers?.length > 0 && (
                      <AvatarStack
                        data={managers}
                        modalTitle={t('courses.managers')}
                      />
                    )}
                  </div>
                  <div className="card__avatar">
                    <h4 className="card__avatar-title">
                      {t('projects.titleStudents')} ({students?.length})
                    </h4>
                    {students?.length > 0 && (
                      <AvatarStack
                        data={students}
                        modalTitle={t('courses.students')}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {detailedCourse?.about && (
              <p className="course-about__description u-mb-0">
                {detailedCourse?.about}
                {detailedCourse?.about.length > 300 && '...'}

                {detailedCourse?.about.length > 300 && (
                  <button
                    className="btn btn--link u-p-0"
                    style={{ height: 'auto' }}
                    onClick={() => setShowModal(true)}
                  >
                    Ver mais
                  </button>
                )}
              </p>
            )}

            <Modal
              show={showModal}
              onClose={() => setShowModal(false)}
              contentClassName="modal__content--md"
            >
              <h3 className="modal__simple-title">Sobre o curso</h3>

              <div className="modal__body u-mb-0">
                <p>{detailedCourse?.about}</p>
              </div>
            </Modal>
          </div>

          <div className="course-about">
            <div>
              <div className="filter-bar">
                <div className="filter-bar__inner">
                  <button
                    onClick={() => setShowModule(false)}
                    className={showModule ? 'filter-bar__back filter-bar__back--disabled' : 'filter-bar__back'}
                    style={{ alignSelf: 'center' }}
                  >
                    <span className="filter-bar__back-container">
                      <span className="filter-bar__title">{t('textsCommon.courseModules')}</span>
                    </span>
                  </button>

                  {showModule && (
                    <button
                      className="filter-bar__back"
                      onClick={() => setShowModule(false)}
                    >
                      <span className="card__icon-wrapper">
                        <FiChevronLeft className="card__icon" />
                      </span>

                      <span className="filter-bar__back-container">
                        <span className="filter-bar__title">
                          {currentModule.title ?? 'Nome do módulo'} {currentModule?.['official_content'] && <IconVerified />}
                        </span>
                      </span>
                    </button>
                  )}
                </div>
              </div>

              {!showModule ? (
                <>
                  <div className="filter-bar filter-bar--borderless">
                    <div className="filter-bar__inner">
                      <div className="filter-bar__row">
                        <label
                          htmlFor="search"
                          className="filter-bar__label"
                        >
                          {t('textsCommon.search')}
                        </label>
                        <input
                          className="form__control form__control--search-with-icon"
                          placeholder="Pesquisar por módulo"
                          type="search"
                          name="search"
                          id="search"
                          onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {detailedCourse?.['learning-systems']?.length === 0 && (
                    <EmptyState
                      type="add"
                      title="Ainda não temos módulos aqui"
                      text="que tal criá-los?"
                      bgless
                    />
                  )}

                  {detailedCourse?.['learning-systems']?.length > 0 && (
                    <div className="round-dg-wrapper">
                      <table
                        className="round-dg round-dg--light"
                        style={{ minWidth: '800px' }}
                      >
                        <thead className="round-dg__header">
                          <tr className="round-dg__row">
                            <th className="round-dg__cell-header">Módulo</th>
                            <th
                              className="round-dg__cell-header"
                              style={{ width: '116px' }}
                            >
                              Aulas
                            </th>
                            <th
                              className="round-dg__cell-header"
                              style={{ width: '132px' }}
                            >
                              Atualização{' '}
                              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                                <FiChevronDown className="card__icon" />
                              </span>
                            </th>
                            <th
                              className="round-dg__cell-header"
                              style={{ width: '132px' }}
                            />
                            <th
                              className="round-dg__cell-header"
                              style={{ width: '160px' }}
                            />
                            <th
                              className="round-dg__cell-header"
                              style={{ width: '60px' }}
                            />
                          </tr>
                        </thead>

                        <tbody className="round-dg__body">
                          {filteredModules?.map(item => {
                            return (
                              <ModuleCard
                                course={detailedCourse}
                                module={item}
                                newMessage={item.has_new_message}
                                moduleId={item.id}
                                showModule={showModule}
                                setShowModule={setShowModule}
                                setCurrentModule={setCurrentModule}
                                key={item.id}
                              />
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              ) : (
                <ViewModule moduleId={currentModule?.id} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
