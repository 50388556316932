import React from 'react';
import timeIcon from './img/time-icon.svg';
import SpinWheel from './SpinWheel';
import styles from './styles.module.scss';
import { FiStar, FiX } from 'react-icons/fi';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import DuelIcon from 'app/components/Icons/DuelIcon';
import { useSession } from 'app/hooks/useSession';
import Avatar from 'app/components/Avatar';

export default function Name({ duel }) {
  const { currentChallenge, rightAnswers } = useWeeklyChallenge();
  const { session } = useSession();

  const userAvatar = session?.user?.image?.url;
  const opponentAvatar = duel?.opponent?.image?.url;

  return (
    <section className={`${styles['player-modal__section']} ${styles['player-modal__section--name']}`}>
      <div className={styles['player-modal__hat']}>
        <span>{duel?.['parent-taxonomy']?.name}</span>
        <p className={styles['player-modal__name-section-subtitle']}>
          <DuelIcon className={styles['player-modal__name-section-icon']} /> Duelo
        </p>
      </div>

      <div className={styles['player-modal__infos']}>
        <div className={styles['player-modal__participant']}>
          <div className={`${styles['player-modal__participant--img']}`}>
            <Avatar
              src={duel?.['creator-id'] === session?.user?.id ? userAvatar : opponentAvatar}
              style={{ width: '70px', height: '70px' }}
            />
            <FiStar className={`${styles['player-modal__participant--img__star-icon']}`} />
          </div>
          <span>{duel?.['creator-id'] === session.user.id ? session.user.name : duel?.opponent?.name}</span>
        </div>
        <div className={styles['player-modal__infos--values']}>
          <span>{duel?.['creator-id'] === session.user.id ? rightAnswers : duel?.opponent?.['rounds-won']}</span>
          <FiX />
          <span>{duel?.['invited-id'] === session.user.id ? rightAnswers : duel?.opponent?.['rounds-won']}</span>
        </div>
        <div className={styles['player-modal__participant']}>
          <div className={`${styles['player-modal__participant--img']}`}>
            <Avatar
              src={duel?.['invited-id'] === session.user.id ? userAvatar : opponentAvatar}
              style={{ width: '70px', height: '70px' }}
            />
          </div>
          <span>{duel?.['invited-id'] === session.user.id ? session.user.name : duel?.opponent?.name}</span>
        </div>
      </div>

      {currentChallenge && <SpinWheel />}

      <p className={`${styles['player-modal__warning']} fadeInLeft`}>
        <img
          src={timeIcon}
          alt="ícone tempo"
        />
        Tente fazer no menor tempo!
      </p>
    </section>
  );
}
