import { FiCalendar, FiCheckCircle, FiClipboard, FiCoffee, FiEdit3, FiHome, FiMap, FiMessageSquare, FiMonitor, FiStar, FiUsers, FiVideo } from 'react-icons/fi';
import { PiStudent, PiTreeStructureLight } from 'react-icons/pi';
import { BsBoxes, BsFileEarmarkCheck } from 'react-icons/bs';
import { VscGame } from 'react-icons/vsc';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import monitor from './img/monitor.svg';
import organization from './img/organization.svg';
import chart from './img/chart.svg';
import check from './img/check.svg';
import activity from './img/activity.svg';
import message from './img/message.svg';
import getPermission from 'app/utils/getPermission';
import IconPerformance from '../Icons/IconPerformance';
import IconExercises from '../Icons/IconExercises';
import IconExams from '../Icons/IconExams';
import IconSimulated from '../Icons/IconSimulated';

export function adminMenu() {
  const { abilities } = usePlatformAbilities();

  const ViewLiveClass = getPermission('Visualizar aulas ao vivo', 'Aulas ao vivo');
  const viewLessonPlan = getPermission('Visualizar Plano de Aula', 'Plano de Aula');
  const viewCourse = getPermission('Visualizar lista de cursos', 'Cursos');
  const viewClassroom = getPermission('Visualizar lista de turmas', 'Turmas');
  const viewTaxonomies = getPermission('Visualizar lista de ementas, tópicos e subtópicos', 'Ementas');
  const viewMetrics =
    getPermission('Visualizar dados e métricas', 'Questionário de Percepção') ||
    getPermission('Visualizar dados e métricas', 'Projetos') ||
    getPermission('Visualizar dados e métricas', 'Participantes e perfis administrativos') ||
    getPermission('Visualizar dados e métricas', 'Arquivos e atividades complementares') ||
    getPermission('Visualizar dados e métricas', 'Questões e exercícios') ||
    getPermission('Visualizar dados e métricas', 'Provas e simulados') ||
    getPermission('Visualizar dados e métricas', 'Ementas') ||
    getPermission('Visualizar dados e métricas', 'Fórum') ||
    getPermission('Visualizar dados e métricas', 'Cursos') ||
    getPermission('Visualizar dados e métricas', 'Turmas') ||
    getPermission('Visualizar dados e métricas', 'Tarefas');

  return [
    {
      id: 1,
      route: '/',
      icon: <FiHome className="menu__icon" />,
      label: 'Home'
    },
    {
      id: 2,
      icon: <FiMonitor className="menu__icon" />,
      submenu: {
        img: monitor,
        title: 'Cursos e Aulas',
        subtitle: 'Videoaulas, material didático e aulas ao vivo',
        nav: [
          {
            route: '/curso/admin',
            icon: <FiMonitor className="submenu__icon" />,
            title: 'Cursos',
            description: 'Aulas e materiais complementares',
            visible: abilities?.cursos
          },
          {
            route: '/aulas-ao-vivo',
            icon: <FiVideo className="submenu__icon" />,
            title: 'Aulas ao vivo',
            description: 'Sessões interativas em tempo real',
            visible: abilities?.['aulas-ao-vivo'] && ViewLiveClass
          },
          {
            route: '/plano-de-aulas/inicio',
            icon: <FiMap className="submenu__icon" />,
            title: 'Planos de aula',
            description: 'Gerencie guias de aula estruturadas',
            visible: viewLessonPlan && abilities?.['plano-de-aula']
          }
        ]
      }
    },
    {
      id: 3,
      icon: (
        <BsBoxes
          className="menu__icon"
          style={{ strokeWidth: 0 }}
        />
      ),
      submenu: {
        img: organization,
        title: 'Organização da Plataforma',
        subtitle: 'Gerenciamento de turmas, usuários e estrutura de conteúdo',
        nav: [
          {
            route: '/turmas',
            icon: <PiStudent className="submenu__icon" />,
            title: 'Turmas',
            description: 'Crie e gerencie turmas e alunos',
            visible: (viewCourse || viewClassroom) && abilities?.cursos
          },
          {
            route: '/participantes',
            icon: <FiUsers className="submenu__icon" />,
            title: 'Usuários',
            description: 'Crie e gerencie perfis e usuários',
            visible:
              getPermission('Visualizar lista de participantes', 'Participantes e perfis administrativos') ||
              getPermission('Visualizar lista e gerenciar perfis', 'Participantes e perfis administrativos') ||
              getPermission('Enviar notificações de engajamento', 'Participantes e perfis administrativos')
          },
          {
            route: '/ementa/materias',
            icon: <PiTreeStructureLight className="submenu__icon" />,
            title: 'Ementa',
            description: 'Defina a estrutura do conteúdo pedagógico',
            visible: viewTaxonomies
          }
        ]
      }
    },
    {
      id: 4,
      icon: <IconPerformance className="menu__icon" />,
      submenu: {
        img: chart,
        title: 'Análise',
        subtitle: 'Acompanhe e analise os resultados de aprendizado.',
        nav: [
          {
            route: '/metricas/institucional',
            icon: <IconPerformance className="submenu__icon" />,
            title: 'Relatórios',
            description: 'Acompanhe a performance, cadastro, engajamento e mais',
            visible: viewMetrics && abilities?.metricas
          }
        ]
      }
    },
    {
      id: 5,
      icon: <FiCheckCircle className="menu__icon" />,
      submenu: {
        img: check,
        title: 'Ferramentas de Avaliação',
        subtitle: 'Teste conhecimentos e monitore resultados',
        nav: [
          {
            route: '/avaliacoes/banco-de-questoes',
            icon: <FiCheckCircle className="submenu__icon" />,
            title: 'Banco de questões',
            description: 'Repositório para avaliação e exercícios',
            visible:
              (getPermission('Visualizar lista de provas', 'Provas e simulados') ||
                getPermission('Visualizar banco de questões', 'Questões e exercícios') ||
                getPermission('Visualizar exercícios', 'Questões e exercícios') ||
                getPermission('Visualizar resultados', 'Provas e simulados')) &&
              abilities?.avaliacoes
          },
          {
            route: '/avaliacoes/apostilas',
            icon: <IconExercises className="submenu__icon" />,
            title: 'Exercícios',
            description: 'Crie e gerencie cadernos de exercícios',
            visible:
              (getPermission('Visualizar lista de provas', 'Provas e simulados') ||
                getPermission('Visualizar banco de questões', 'Questões e exercícios') ||
                getPermission('Visualizar exercícios', 'Questões e exercícios') ||
                getPermission('Visualizar resultados', 'Provas e simulados')) &&
              abilities?.avaliacoes
          },
          {
            route: '/avaliacoes/provas',
            icon: <IconExams className="submenu__icon" />,
            title: 'Provas',
            description: 'Avalie o conhecimento dos estudantes',
            visible:
              (getPermission('Visualizar lista de provas', 'Provas e simulados') ||
                getPermission('Visualizar banco de questões', 'Questões e exercícios') ||
                getPermission('Visualizar exercícios', 'Questões e exercícios') ||
                getPermission('Visualizar resultados', 'Provas e simulados')) &&
              abilities?.avaliacoes
          },
          {
            route: '/avaliacoes/simulados',
            icon: <IconSimulated className="submenu__icon" />,
            title: 'Simulados',
            description: 'Crie avaliações simulando exames reais',
            visible:
              (getPermission('Visualizar lista de provas', 'Provas e simulados') ||
                getPermission('Visualizar banco de questões', 'Questões e exercícios') ||
                getPermission('Visualizar exercícios', 'Questões e exercícios') ||
                getPermission('Visualizar resultados', 'Provas e simulados')) &&
              abilities?.avaliacoes
          },
          {
            route: '/questionario',
            icon: <FiStar className="submenu__icon" />,
            title: 'Questionário de percepção',
            description: 'Colete feedback dos estudantes',
            visible: getPermission('Visualizar questionário de percepção', 'Questionário de Percepção') && abilities?.nps
          }
        ]
      }
    },
    {
      id: 6,
      icon: <FiEdit3 className="menu__icon" />,
      submenu: {
        img: activity,
        title: 'Atividades',
        subtitle: 'Enriqueça a experiência de aprendizado',
        nav: [
          {
            route: '/gestao-de-tarefa/curso',
            icon: <FiCalendar className="submenu__icon" />,
            title: 'Tarefas',
            description: 'Crie e atribua tarefas aos estudantes',
            visible: getPermission('Visualizar Tarefas', 'Tarefas') && abilities?.tarefas
          },
          {
            route: '/atividade-complementar',
            icon: <BsFileEarmarkCheck className="submenu__icon" />,
            title: 'Atividade Complementar',
            description: 'Ofereça atividades de estudo individuais',
            visible: getPermission('Visualizar lista de atividades', 'Arquivos e atividades complementares') && abilities?.['cursos-ativ-complementar']
          },
          {
            route: '/desafio-da-semana/desafios',
            icon: (
              <VscGame
                className="submenu__icon"
                strokeWidth={0.6}
              />
            ),
            title: 'Desafio da semana',
            description: 'Elabore disputas de conhecimento para os estudantes',
            visible: abilities?.['desafio-da-semana']
          },
          {
            route: '/redacao',
            icon: <FiEdit3 className="submenu__icon" />,
            title: 'Redação',
            description: 'Atribua e avalie as habilidades de escrita dos estudantes.',
            visible: abilities?.redacao
          },
          {
            route: '/projetos/grupos',
            icon: <FiClipboard className="submenu__icon" />,
            title: 'Projetos',
            description: 'Crie e acompanhe a evolução de  trabalhos colaborativo',
            visible: getPermission('Visualizar lista de projetos', 'Projetos') && abilities?.projetos
          }
        ]
      }
    },
    {
      id: 7,
      icon: <FiMessageSquare className="menu__icon" />,
      submenu: {
        img: message,
        title: 'Comunidade',
        subtitle: 'Aqui todos se reúnem para partilhar e aprender',
        nav: [
          {
            route: '/auditorio',
            icon: <FiMessageSquare className="submenu__icon" />,
            title: 'Monitoria',
            description: 'Forneça orientação em tempo real',
            visible: abilities?.auditorio && getPermission('Visualizar monitorias', 'Monitorias')
          },
          {
            route: '/feed',
            icon: <FiCoffee className="submenu__icon" />,
            title: 'Fórum',
            description: 'Troque experiências com alunos e outros professores',
            visible: getPermission('Visualizar postagens e comentários', 'Fórum') && abilities?.feed
          }
        ]
      }
    }
  ];
}
