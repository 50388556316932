import React, { useState } from 'react';
import MaterialCard from './MaterialCard';
import EmptyState from 'app/components/EmptyState';
import { t } from 'i18next';

export default function TabFiles({ currentModule }) {
  const [searchTerm, setSearchTerm] = useState('');

  const moduleLessons = currentModule?.['learning-system-contents']
    ?.map(content =>
      content.learning_system_items.map(item => {
        return item;
      })
    )
    .flat();

  const filteredFiles = currentModule?.['learning-system-files']?.filter(file => {
    if (searchTerm == '') {
      return file;
    }
    if (file.title !== null && file.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
      return file;
    }
  });

  return (
    <div className="fadeIn">
      <div className="filter-bar">
        <div className="filter-bar__row">
          <label
            htmlFor="search"
            className="filter-bar__label"
          >
            {t('textsCommon.search')}
          </label>
          <input
            className="form__control form__control--search-with-icon"
            placeholder={t('filter.labelSearchFiles')}
            type="search"
            name="search"
            id="search"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {filteredFiles && filteredFiles?.length > 0 ? (
        <div className="round-dg-wrapper">
          <table
            className="round-dg round-dg--light"
            style={{ minWidth: 700 }}
          >
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th className="round-dg__cell-header round-dg__cell-header--sm">Nome do material</th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: 160 }}
                >
                  Aula
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: 140 }}
                ></th>
              </tr>
            </thead>

            <tbody className="round-dg__body">
              {filteredFiles?.map(material => {
                return (
                  <MaterialCard
                    material={material}
                    moduleLessons={moduleLessons}
                    currentModule={currentModule}
                    key={material?.id}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyState type="data" />
      )}
    </div>
  );
}
