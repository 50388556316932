import * as React from 'react';

function IconExercises({ size, ...restProps }) {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth={2}
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={size ?? '1em'}
      height={size ?? '1em'}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M4 3a2 2 0 012-2h15a2 2 0 012 2v18a2 2 0 01-2 2H6a2 2 0 01-2-2V3z" />
      <path d="M1 12h6M1 17h6M1 7h6" />
      <path d="M10.5 1L10.5 23" />
    </svg>
  );
}

export default IconExercises;
