import { navigate } from '@reach/router';
import Avatar from '../Avatar';
import styles from './styles.module.scss';
import { VscGame } from 'react-icons/vsc';
import avatar1 from './images/img1.png';
import avatar2 from './images/img2.png';
import avatar3 from './images/img3.png';

export default function DuelCard() {
  return (
    <div className={`card ${styles['duel-card']}`}>
      <span className={`${styles['duel-card__tag']}`}>Novidade</span>
      <div className={`${styles['duel-card__images']}`}>
        <Avatar
          src={avatar1}
          className={`${styles['duel-card__images-1']}`}
        />
        <Avatar
          src={avatar2}
          className={`${styles['duel-card__images-2']}`}
        />
        <Avatar
          src={avatar3}
          className={`${styles['duel-card__images-3']}`}
        />
      </div>
      <h3 className={`${styles['duel-card__title']}`}>Desafie seus colegas</h3>
      <p>Participe de duelos e teste seus conhecimentos</p>
      <button
        className={`btn btn--turbo`}
        onClick={() => navigate('/duelos')}
      >
        <VscGame className="btn__icon" />
        Iniciar Duelos
      </button>
    </div>
  );
}
