import BreadCrumbs from 'app/components/BreadCrumbs';
import Loader from 'app/components/loader';
import { useSession } from 'app/hooks/useSession';
import React, { useEffect, useRef, useState } from 'react';
import KpiWidget from './KpiWidget';
import FastAccessWidget from './FastAccessWidget';
import { FiEdit3, FiMap, FiMessageSquare, FiMonitor, FiUsers } from 'react-icons/fi';
import { BsFileEarmarkCheck } from 'react-icons/bs';
import IconPerformance from 'app/components/Icons/IconPerformance';
import { VscGame } from 'react-icons/vsc';
import { useNotification } from 'app/hooks/useNotification';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import Notification from 'app/components/DropdownMessages/Notification';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

const DashboardTeacher = () => {
  const [loading, setLoading] = useState(false);
  const { session } = useSession();
  const { t } = useTranslation();
  const { notificationsComment, setNotificationsComment, notificationCommentCount, setNotificationCommentCount, getNotificationsComment } = useNotification();
  const client = useClient();
  const [pendingCorrections, setPendingCorrections] = useState();
  const { abilities } = usePlatformAbilities();

  const getPendingCorrections = async () => {
    const { data, error } = await client.fetch('/dashboards/cards');

    if (error) {
      toast.error('Erro ao buscar correções pendentes');
    } else {
      setPendingCorrections(data);
    }
  };

  useEffect(() => {
    getPendingCorrections();
  }, []);

  const unreadNotifications = notificationsComment?.filter(item => item.unread === true);

  const breadCrumbs = {
    title: 'Início',
    nav: [
      {
        route: '/',
        name: `Olá, ${session?.user?.name}`,
        isActive: true
      }
    ]
  };

  const shortcuts = [
    {
      title: 'Monitoria',
      link: '/auditorio',
      icon: (
        <FiMessageSquare
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      available: abilities?.auditorio
    },
    {
      title: 'Redação',
      icon: (
        <FiEdit3
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/redacao',
      available: abilities?.redacao
    },
    {
      title: 'Atividades',
      icon: (
        <BsFileEarmarkCheck
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/atividade-complementar',
      available: abilities?.['cursos-ativ-complementar']
    },
    {
      title: 'Cursos',
      icon: (
        <FiMonitor
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/curso/admin',
      available: abilities?.cursos
    },
    {
      title: 'Plano de aula',
      icon: (
        <FiMap
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/plano-de-aulas/inicio'
    },
    {
      title: 'Minhas turmas',
      icon: (
        <FiUsers
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/turmas',
      available: abilities?.turmas
    },
    {
      title: 'Desafio da semana',
      icon: (
        <VscGame
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/desafio-da-semana/desafios',
      available: abilities?.['desafio-da-semana']
    },
    {
      title: 'Relatórios',
      icon: (
        <IconPerformance
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/metricas/institucional',
      available: abilities?.metricas
    }
  ];

  return loading ? (
    <Loader />
  ) : (
    <>
      <BreadCrumbs data={breadCrumbs} />

      <section className="frequent-shortcut-widget-wrapper">
        <h3 className="frequent-shortcut-widget-wrapper__title">Para corrigir</h3>

        <div className="kpi-widget-wrapper u-mb-0">
          {abilities?.redacao && (
            <KpiWidget
              title="Redações para revisar"
              count={pendingCorrections?.['surveys_count']}
              link="Acessar redações"
              route="/redacao"
            />
          )}
          {abilities?.projetos && (
            <KpiWidget
              title="Projetos (versões para avaliar)"
              count={pendingCorrections?.['project_versions_count']}
              link="Acessar projetos"
              route="/projetos/grupos"
            />
          )}
          {abilities?.['cursos-ativ-complementar'] && (
            <KpiWidget
              title="Atividades para avaliar"
              count={pendingCorrections?.['activities_count']}
              link="Acessar atividades"
              route="/atividade-complementar"
            />
          )}
        </div>
      </section>

      <section className="frequent-shortcut-widget-wrapper">
        <h3 className="frequent-shortcut-widget-wrapper__title">Acessos rápidos</h3>

        <div className="fast-access-wrapper">
          {shortcuts
            ?.filter(item => item.available)
            ?.map(item => (
              <FastAccessWidget
                title={item.title}
                icon={item.icon}
                link={item.link}
              />
            ))}
        </div>
      </section>

      <section className="frequent-shortcut-widget-wrapper">
        <h3 className="frequent-shortcut-widget-wrapper__title">Mensagens</h3>

        {unreadNotifications.length === 0 && (
          <EmptyState
            type="notification"
            title={t('emptyState.notificationTitle')}
          />
        )}
        {unreadNotifications?.map(notification => (
          <div className="notifications__body notifications__body--dashboard">
            <Notification
              notifications={notificationsComment}
              notification={notification}
              setNotificationCount={setNotificationCommentCount}
              setNotifications={setNotificationsComment}
              notificationCount={notificationCommentCount}
              key={notification.id}
            />
          </div>
        ))}
      </section>
    </>
  );
};

export default DashboardTeacher;
