import React from 'react';
import { FiFileText, FiLink, FiVideo } from 'react-icons/fi';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';
import { PiFilePdf } from 'react-icons/pi';

export default function MaterialCard({ material, moduleLessons, currentModule }) {
  const fileLesson = moduleLessons?.find(item => item.id === parseInt(material?.['learning-system-item-ids']));
  const client = useClient();

  const handleButtonClick = async () => {
    await client.mutate('route_logs', {
      uri: `/materiais/${material?.id}/${currentModule?.id}`,
      path: '/materiais/:materialId/:moduleId'
    });
  };

  const materialUrl = material?.url;
  const materialFileUrl = material?.file?.url;

  const isPdf = materialUrl?.includes('.pdf') || materialFileUrl?.includes('.pdf');
  const isFileLink = materialFileUrl && !materialFileUrl?.includes('.pdf');
  const isVideo = materialUrl?.includes('.mp4');
  const isAudio = materialUrl?.includes('.mp3');

  function MaterialIcon() {
    if (isPdf) return <PiFilePdf size={24} />;
    if (isFileLink) return <FiLink size={24} />;
    if (isVideo) return <FiVideo size={24} />;
    if (isAudio) return <FiHeadphones size={24} />;

    return <FiFileText size={24} />;
  }

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div className="card__header">
          <div className="card__inner-header">
            <div
              className="card__icon-wrapper"
              style={{ width: 32, height: 32 }}
            >
              <MaterialIcon />
            </div>
            <h2
              className="card__title card__title--truncate"
              title={material?.title}
            >
              {material?.title}
            </h2>
          </div>
        </div>
      </th>
      <td className="round-dg__cell">
        <span
          className="expanded-calendar__tag expanded-calendar__tag--bright u-text-truncate"
          style={{ display: 'inline-block' }}
        >
          {fileLesson?.body}
        </span>
      </td>
      <td className="round-dg__cell last-cell">
        <a href={material?.file?.url ? material?.file?.url : material?.url}>
          <button
            type="button"
            className="btn btn--outline btn--small"
            onClick={handleButtonClick}
          >
            {t('button.view')}
          </button>
        </a>
      </td>
    </tr>
  );
}
