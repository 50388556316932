import React, { useEffect, useState } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { VscGame } from 'react-icons/vsc';
import checkIcon from './img/check-icon.svg';
import xIcon from './img/x-icon.svg';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import Loader from 'app/components/loader';

export default function DuelQuestion() {
  const client = useClient();
  const { currentChallenge, loading, setStart, counter, setCurrentStep, setRightAnswers, questions, getQuestions, currentRound } = useWeeklyChallenge();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [correctAlternativeId, setCorrectAlternativeId] = useState();

  const handleAnswerQuestion = async alternativeId => {
    setStart(false);
    setIsSubmitting(true);
    setSelectedAnswer(alternativeId);

    const payload = {
      selected_alternative_id: alternativeId,
      question_id: questions?.id
    };

    await client
      .mutate(`/duels/${currentChallenge.id}/answer`, payload, { method: 'PUT' })
      .then(response => {
        const currentAnswer = questions?.id;
        setCurrentAnswer(currentAnswer);
        setCorrectAlternativeId(response?.data?.question?.['correct_alternative_id']);
        if (alternativeId === response?.data?.question?.['correct_alternative_id']) {
          setRightAnswers(prev => prev + 1);
        }
        setTimeout(() => {
          setCurrentStep(3);
        }, 3000);
      })
      .catch(err => {
        toast.error('Erro ao responder questão');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (counter === 0) {
      handleAnswerQuestion(null, 'skipped');
    }
  }, [counter]);

  useEffect(() => {
    getQuestions();
  }, [currentRound]);

  if (loading) return <Loader />;

  return (
    <div className="challenge-question">
      <span className="challenge-question__count">
        <VscGame /> {currentRound}
      </span>

      <div className="challenge-question__header">
        <h3 className="challenge-question__title">{questions?.content}</h3>
      </div>

      <div className="challenge-question__body">
        <div className="challenge-question__alternatives-list">
          {questions?.alternatives?.map(item => {
            return (
              <button
                key={item?.id}
                disabled={isSubmitting}
                className={`challenge-question__alternative ${selectedAnswer && correctAlternativeId && selectedAnswer === item.id && selectedAnswer !== correctAlternativeId && 'challenge-question__alternative--danger'}  ${
                  selectedAnswer && correctAlternativeId && selectedAnswer === item.id && selectedAnswer === correctAlternativeId && 'challenge-question__alternative--success'
                }`}
                onClick={() => {
                  handleAnswerQuestion(item?.id);
                }}
              >
                <span className="challenge-question__radio" /> {item?.['alternative_text']}
                {selectedAnswer && correctAlternativeId && correctAlternativeId === item.id ? (
                  <img
                    src={checkIcon}
                    alt="ícone de marcado"
                    className="challenge-question__icon"
                  />
                ) : null}
                {selectedAnswer && correctAlternativeId && selectedAnswer === item.id && selectedAnswer === correctAlternativeId ? (
                  <img
                    src={checkIcon}
                    alt="ícone de marcado"
                    className="challenge-question__icon"
                  />
                ) : null}
                {selectedAnswer && selectedAnswer === item.id && selectedAnswer !== correctAlternativeId ? (
                  <img
                    src={xIcon}
                    alt="ícone de errado"
                    className="challenge-question__icon"
                  />
                ) : null}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
