import React, { useEffect, useState } from 'react';
import { Link, navigate, useParams } from '@reach/router';
import { FiChevronLeft, FiUsers } from 'react-icons/fi';
import LiveClassChat from './LiveClassChat';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import ActionCableConsumer from 'app/utils/actionCableConsumer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import EmptyState from 'app/components/EmptyState';
import NPSModal from 'app/components/NPSModal';
import { YoutubeWithSurvey } from 'app/components/YoutubeWithSurvey';
import { VimeoWithSurvey } from 'app/components/VimeoWithSurvey';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function ViewLiveClass(props) {
  const { uri } = props;

  const client = useClient();
  const { t } = useTranslation();
  const isStudent = checkIncludeProfiles({ only: ['student'] });
  const [classData, setClassData] = useState(null);
  const [messageChannel, setMessageChannel] = useState(null);
  const [comments, setComments] = useState([]);
  const [videoId, setVideoId] = useState();
  const [videoPlatform, setVideoPlatform] = useState(null);
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [nextClassStarted, setNextClassStarted] = useState(false);
  const [intervalId, setIntervalId] = useState();
  const [isOnSchedule, setIsOnSchedule] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300);

  useEffect(() => {
    setClassData(null);
    setMessageChannel(null);
    setComments([]);
    setVideoId(null);
    setVideoPlatform(null);
    setHasMoreComments(true);
    setPage(1);
    setShowModal(false);
    setAnswers([]);
    setUsersCount(0);
    setNextClassStarted(false);
    setTimeLeft(300);
    clearInterval(intervalId);

    // setIntervalId(setInterval(checkNextClassTime, 1000));
    async function loadData() {
      try {
        await Promise.all([fetchLiveClassById(), fetchComments(), fetchSurveyIsAnswered()]);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    loadData();
  }, [props.liveClassId]);

  const fetchLiveClassById = async () => {
    try {
      const response = await client.fetch(`/live_classrooms/${props.liveClassId}`);

      setClassData(response.data);
    } catch (error) {
      console.log(error);
      toast.error('Erro');
    }
  };

  const fetchSurveyIsAnswered = async () => {
    const { data, error } = await client.fetch(`live_classroom_answers?filter[live_classroom_id]=${props.liveClassId}`);

    if (error) return;

    if (data.length < 5) {
      setAnswers(data);
    }
  };

  async function fetchComments() {
    try {
      const url = `/live_classrooms/${props.liveClassId}/live_classroom_messages?page[number]=${page}&page[size]=20&order=created_at desc`;
      const { data, error } = await client.fetch(url);

      if (error || data.length === 0) {
        setHasMoreComments(false);
        return;
      }

      if (data.length < 20) {
        setHasMoreComments(false);
      }

      if (comments) {
        setComments(prevState => [...data, ...prevState]);
      } else {
        setComments(data);
      }
    } catch (error) {
      console.log(error);
      setHasMoreComments(false);
      toast.error('Erro');
    }
  }

  const checkVideoPlayer = url => {
    const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    const vimeoRegex = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

    const youtubeResult = url.match(youtubeRegex);
    const vimeoResult = url.match(vimeoRegex);
    setVideoPlatform(null);
    if (youtubeResult) {
      setTimeout(() => setVideoPlatform('youtube'), 500);
      setVideoId(youtubeResult[6]);
      return;
    } else if (vimeoResult) {
      setTimeout(() => setVideoPlatform('vimeo'), 500);
      setVideoId(vimeoResult[4]);
    } else {
      setTimeout(() => setVideoPlatform('iframe'), 500);
      setVideoId(url);
    }
  };

  useEffect(() => {
    if (messageChannel) {
      if (messageChannel?.count) {
        setUsersCount(messageChannel.count);
      } else {
        let parsedMessage;

        try {
          parsedMessage = JSON.parse(messageChannel.data);
        } catch (error) {
          return;
        }
        if (parsedMessage.count !== undefined) {
          const count = parsedMessage.count;
        } else if (messageChannel?.action === 'created' && parsedMessage.data && parsedMessage.data.type === 'live-classroom-message') {
          const newMessage = parsedMessage.data.attributes;

          if (comments.length < 50) {
            setComments(prevState => [...prevState, newMessage]);
          } else {
            const lastFourtyNineComments = comments.slice(1, 50);
            setComments([...lastFourtyNineComments, newMessage]);
          }
        } else if (messageChannel?.action === 'deleted') {
          const messageIdToDelete = parsedMessage?.data?.attributes?.id;
          setComments(prevState => prevState.filter(comment => comment.id !== messageIdToDelete));
        }
      }
    }
  }, [messageChannel]);

  useEffect(() => {
    if (page !== 1) {
      fetchComments();
    }
  }, [page]);

  useEffect(() => {
    if (classData?.url) {
      checkVideoPlayer(classData.url);
    }
  }, [classData]);

  const checkNextClassTime = () => {
    const currentDate = moment();
    setIsOnSchedule(currentDate.isBetween(classData?.['initial-date'], classData?.['end-date']));
    const teste = new Date() >= new Date(classData?.['next-live-classroom']?.['initial-date']);
    setNextClassStarted(teste);
    if (timeLeft >= 0 && teste) {
      setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
      // setTimeLeft(timeLeft - 1);
    }
  };

  useEffect(() => {
    if (classData) {
      setIntervalId(setInterval(checkNextClassTime, 1000));
      return () => clearInterval(intervalId);
    }
  }, [classData]);

  if (!isOnSchedule && !isLoading) {
    return (
      <main
        className="main-content"
        style={{ justifyContent: 'center', flexDirection: 'column' }}
      >
        <EmptyState
          type="content"
          title={t('emptyState.contentNotAvaliable')}
          text={t('emptyState.contactATeacherText')}
        />
      </main>
    );
  }

  const breadCrumbs = {
    title: 'Cursos e Aulas',
    nav: [
      {
        route: '/aulas-ao-vivo',
        name: 'Aulas ao vivo',
        isActive: false
      },
      {
        route: uri,
        name: classData?.title,
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--auditorium">
      {classData && (
        <ActionCableConsumer
          channel="LiveClassroomMessageChannel"
          setObjectChannel={setMessageChannel}
          channelKey={'live_classroom_id'}
          channelId={classData?.id}
        />
      )}

      <BreadCrumbs data={breadCrumbs} />

      <div className="page">
        <div className="page__col page__col--live-class">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              className="badge badge--tiny badge--success"
              style={{ marginRight: '8px' }}
            >
              Online
            </span>
            {usersCount > 0 && (
              <span
                className="badge badge--tiny badge--more"
                style={{ cursor: 'default' }}
              >
                <FiUsers /> {usersCount} {t('textsCommon.watchingNow')}
              </span>
            )}
          </div>

          <div className="live-class-player">
            <div className="live-class-player__embed">
              {videoPlatform && videoPlatform === 'youtube' && (
                <YoutubeWithSurvey
                  showModalSurvey={showModal}
                  setShowModalSurvey={setShowModal}
                  videoId={videoId}
                  answers={answers}
                />
              )}
              {videoPlatform && videoPlatform === 'vimeo' && (
                <VimeoWithSurvey
                  videoId={videoId}
                  setShowModalSurvey={setShowModal}
                  showModalSurvey={showModal}
                />
              )}
              {timeLeft >= 0 && videoPlatform && videoPlatform === 'iframe' && (
                <iframe
                  src={videoId}
                  scrolling="no"
                ></iframe>
              )}
              {nextClassStarted && (
                <div className={`live-class-player_overlay ${timeLeft <= 0 ? 'live-class-player_overlay--noise' : ''}`}>
                  <div className="live-class-player_overlay__inner">
                    <div className="live-class-player_overlay__title">Sua próxima aula já começou</div>

                    <div className="live-class-player_overlay__body">{timeLeft >= 0 ? `Esta transmissão será encerrada em ${moment().startOf('day').seconds(timeLeft).format('mm:ss')}` : 'Clique no botão abaixo para assistir'}</div>
                    <Link
                      to={`/aulas-ao-vivo/${classData?.['next-live-classroom']?.id}`}
                      className="btn btn--outline btn--small btn--wide"
                    >
                      Próxima aula
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="live-class-player">
            <div className="live-class-about">
              <div className="live-class-about__header">
                <h3 className="live-class-about__title">{t('liveClass.aboutTitle')}</h3>
              </div>

              <div className="live-class-about__body">
                <p className="live-class-about__text">{classData?.about}</p>
              </div>
            </div>
          </div>
        </div>

        <aside className="page__aside page__aside--chat">
          <LiveClassChat
            comments={comments}
            setComments={setComments}
            hasMoreComments={hasMoreComments}
            setPage={setPage}
          />
        </aside>
      </div>

      {showModal && answers.length < 5 && isStudent && (
        <NPSModal
          answers={answers}
          setAnswers={setAnswers}
        />
      )}
    </main>
  );
}
