import React, { useEffect, useState } from 'react';
import { VscGame } from 'react-icons/vsc';
import { FiArrowRight } from 'react-icons/fi';
import checkIcon from './img/check-icon.svg';
import xIcon from './img/x-icon.svg';
import { useClient, useQuery } from 'jsonapi-react';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';

export default function ChallengeQuestion(props) {
  const { setCurrentIndexQuestion, currentIndexQuestion } = props;
  const client = useClient();
  const { currentChallenge, loading, setChallengeCurrentStatus, generalCount, taxonomies, setStart, counter, currentAttempt, currentRound, setCurrentRound, setCurrentStep, setRightAnswers, questions } = useWeeklyChallenge();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [submittedAnswer, setSubmittedAnswer] = useState(false);

  const startQuestion = async () => {
    await client.mutate(`/challenges/${currentChallenge.id}/challenge_attempt_items/${currentAttempt}`, {
      'challenge-attempt-item-answers-attributes': [{ 'challenge-question-id': questions?.[currentIndexQuestion]?.id }]
    });
  };

  const handleAnswerQuestion = async (alternativeId, status) => {
    setStart(false);
    setSubmittedAnswer(true);
    setIsSubmitting(true);

    const payload = {
      'challenge-attempt-item-answers-attributes': [{ 'challenge-alternative-id': alternativeId, 'challenge-question-id': questions[currentIndexQuestion]?.id }]
    };

    if (status) {
      payload['challenge-attempt-item-answers-attributes'][0].status = status;
    }

    await client
      .mutate(`/challenges/${currentChallenge.id}/challenge_attempt_items/${currentAttempt}`, payload)
      .then(response => {
        const currentAnswer = response.data['challenge-attempt-item-answers'].find(item => item['challenge-question'].id === questions[currentIndexQuestion]?.id);
        setChallengeCurrentStatus(response.data);
        setCurrentAnswer(currentAnswer);
        if (currentAnswer['is-correct']) {
          setRightAnswers(prev => prev + 1);
        }
      })
      .catch(err => {
        toast.error('Erro ao responder questão');
        console.log(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const skippQuestion = async () => {
    await client.mutate(`/challenges/${currentChallenge.id}/challenge_attempt_items/${currentAttempt}`, {
      'challenge-attempt-item-answers-attributes': [{ 'challenge-question-id': questions[currentIndexQuestion]?.id, status: 'skipped' }]
    });
    setCurrentIndexQuestion(prev => prev + 1);
  };

  useEffect(() => {
    if (counter === 0) {
      handleAnswerQuestion(null, 'skipped');
    }
  }, [counter]);

  useEffect(() => {
    startQuestion();
  }, [currentIndexQuestion]);

  const handleNextQuestion = () => {
    if (currentAnswer) {
      if (currentIndexQuestion + 1 < questions?.length) {
        setCurrentIndexQuestion(currentIndexQuestion + 1);
        setSubmittedAnswer(false);
        setStart(true);
      } else if (currentIndexQuestion + 1 === questions?.length && taxonomies.length > 1) {
        setCurrentStep(1);
        setCurrentRound(currentRound + 1);
        setSubmittedAnswer(false);
        setStart(true);
      } else if (currentIndexQuestion + 1 === questions?.length && taxonomies.length === 1) {
        setCurrentStep(3);
        setCurrentRound(currentRound + 1);
        setSubmittedAnswer(false);
        setStart(true);
      }
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="challenge-question">
      <span className="challenge-question__count">
        <VscGame /> {currentIndexQuestion + 1}
      </span>

      <div className="challenge-question__header">
        <h3 className="challenge-question__title">{questions?.[currentIndexQuestion]?.description}</h3>
      </div>

      <div className="challenge-question__body">
        <div className="challenge-question__alternatives-list">
          {questions?.[currentIndexQuestion]?.['challenge-alternatives']?.map(item => {
            return (
              <button
                key={item?.id}
                disabled={isSubmitting}
                className={`challenge-question__alternative ${currentAnswer && currentAnswer['challenge-alternative-id'] === item.id && !currentAnswer['is-correct'] && 'challenge-question__alternative--danger'}  ${
                  currentAnswer && currentAnswer['challenge-alternative-id'] === item.id && currentAnswer['is-correct'] && 'challenge-question__alternative--success'
                }`}
                onClick={() => handleAnswerQuestion(item?.id)}
              >
                <span className="challenge-question__radio" /> {item?.description}
                {currentAnswer && currentAnswer['correct-alternative-id'] === item.id ? (
                  <img
                    src={checkIcon}
                    alt="ícone de marcado"
                    className="challenge-question__icon"
                  />
                ) : null}
                {currentAnswer && currentAnswer['challenge-alternative-id'] === item.id && currentAnswer['is-correct'] ? (
                  <img
                    src={checkIcon}
                    alt="ícone de marcado"
                    className="challenge-question__icon"
                  />
                ) : null}
                {currentAnswer && currentAnswer['challenge-alternative-id'] === item.id && !currentAnswer['is-correct'] ? (
                  <img
                    src={xIcon}
                    alt="ícone de errado"
                    className="challenge-question__icon"
                  />
                ) : null}
              </button>
            );
          })}
        </div>
      </div>

      <div className="challenge-question__footer">
        {!submittedAnswer && questions?.length !== currentIndexQuestion + 1 && (
          <button
            className="btn btn--wide btn--outline"
            onClick={() => {
              skippQuestion();
            }}
          >
            <FiArrowRight className="btn__icon" /> Pular
          </button>
        )}

        {currentAnswer && submittedAnswer ? (
          <>
            <div className="challenge-question__comment">
              <img
                src={currentAnswer['challenge-question']?.avatar?.url}
                alt="foto professor"
                className="avatar avatar--md"
              />

              <div className="challenge-question__comment-content">
                <p>
                  <strong>Comentários do seu professor:</strong> {currentAnswer?.['challenge-question']?.comment}
                </p>
              </div>
            </div>
            <button
              className="btn btn--wide btn--primary"
              style={{ marginTop: 20 }}
              onClick={handleNextQuestion}
            >
              Próxima questão
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}
