import React, { useState, useEffect } from 'react';
import { useNote } from 'app/hooks/useNote';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useSession } from 'app/hooks/useSession';
import { Tooltip } from 'react-tooltip';

function RichTextEditor(props) {
  const { t } = useTranslation();
  const { note, emptyStateTitle, emptyStateText } = props;
  const [importantNote, setImportantNote] = useState(false);
  const { deleteNote, nextBody, setNextBody, setBookmarked, unmarkNote } = useNote();
  const { session } = useSession();

  const isOwnerNote = session?.user?.name === note?.user?.name;

  useEffect(() => {
    setNextBody(note?.body || '');
    setImportantNote(note?.bookmarked);
  }, [note]);

  const onChange = (event, editor) => {
    const data = editor.getData();
    setNextBody(data);
  };

  const handleUncheck = () => {
    Swal.fire({
      title: 'Deseja mesmo desmarcar essa anotação?',
      text: 'Ela sairá da sua lista de anotações importantes',
      confirmButtonText: 'Sim',
      showDenyButton: true,
      denyButtonText: 'Não',
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        unmarkNote(note.id, note);
        setImportantNote(false);
      }
    });
  };

  const handleChecked = () => {
    setBookmarked(note.id, note);
    setImportantNote(true);
  };

  return note?.id ? (
    <>
      <div className="notes__view-header">
        <div className="notes__view-inner-header">
          <h1 className="notes__view-title">{note.title}</h1>
          {isOwnerNote ? (
            <p className="notes__view-date">
              Criação em {note?.['created_at']} • {t('projects.updatedIn')} {note?.['updated_at']}
            </p>
          ) : (
            <p className="notes__view-date">
              {t('projects.updatedIn')} {note?.['updated_at']}
            </p>
          )}
        </div>

        <Tooltip id="important" />
        <button
          className={importantNote ? 'notes__important-btn notes__important-btn--added' : 'notes__important-btn'}
          type="button"
          data-tooltip-id="important"
          data-tooltip-content="Salvar como importante"
          onClick={() => (!importantNote ? handleChecked() : handleUncheck())}
        >
          <svg
            width="17"
            height="21"
            viewBox="0 0 17 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7913 15.2917L8.49967 20.0834L1.20801 15.2917V2.83341C1.20801 2.32508 1.4275 1.83757 1.8182 1.47813C2.2089 1.11868 2.73881 0.916748 3.29134 0.916748H13.708C14.2605 0.916748 14.7904 1.11868 15.1811 1.47813C15.5718 1.83757 15.7913 2.32508 15.7913 2.83341V15.2917Z"
              fill="white"
              stroke="#5D6A86"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      {/* TODO: Inclusão do autor em anotações de turma/importantes que não são minhas.
          <span className="notes__author u-mb-2">
            <Avatar
              src={'https://randomuser.me/api/portraits/women/9.jpg'}
              alt={'João Silva'}
              style={{ width: '24px', height: '24px' }}
            />
            <span className="notes__author-name">{'João Silva'}</span>
          </span> */}

      <div className="notes__view-body">
        {isOwnerNote ? (
          <CKEditor
            editor={Editor}
            note={note}
            onChange={onChange}
            data={nextBody || ''}
            config={{
              placeholder: 'Comece a sua nota por aqui!',
              toolbar: {
                shouldNotGroupWhenFull: true,
                shouldNotGroupWhenOne: true
              }
            }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: note?.body }}></div>
        )}

        {note.bookmarked && note.bookmarked_count === 2 && (
          <div
            className="alert u-mt-2"
            role="alert"
          >
            <span> Você e {note.bookmarked_count - 1} participante marcaram essa anotação como importante</span>
          </div>
        )}
        {note.bookmarked && note.bookmarked_count > 2 && (
          <div
            className="alert u-mt-2"
            role="alert"
          >
            <span> Você e {note.bookmarked_count - 1} participantes marcaram essa anotação como importante</span>
          </div>
        )}
      </div>
    </>
  ) : (
    <EmptyState
      type="select"
      title={emptyStateTitle || t('emptyState.titleNotes')}
      text={emptyStateText || t('emptyState.textNotes')}
      bgless
    />
  );
}

export default RichTextEditor;
