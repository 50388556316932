import * as React from 'react';

function IconExams({ size, ...restProps }) {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth={2}
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={size ?? '1em'}
      height={size ?? '1em'}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1575 23C20.4712 23 23.1575 20.3137 23.1575 17C23.1575 13.6863 20.4712 11 17.1575 11C13.8438 11 11.1575 13.6863 11.1575 17C11.1575 20.3137 13.8438 23 17.1575 23ZM14.339 19.5236C14.4339 19.591 14.5553 19.6248 14.703 19.6248C14.8718 19.6248 14.9984 19.5859 15.0828 19.5081C15.1725 19.4251 15.2569 19.2928 15.336 19.1113L15.6584 18.3644H18.2681L18.596 19.1113C18.6699 19.2876 18.7543 19.4173 18.8492 19.5003C18.9495 19.5833 19.0893 19.6248 19.2686 19.6248C19.411 19.6248 19.5271 19.5936 19.6168 19.5314C19.7065 19.464 19.7592 19.3732 19.775 19.2591C19.7961 19.1398 19.7724 19.0101 19.7038 18.8701L17.6623 14.5055C17.5832 14.3291 17.4856 14.202 17.3696 14.1242C17.2535 14.0412 17.119 13.9997 16.966 13.9997C16.813 13.9997 16.6785 14.0412 16.5625 14.1242C16.4464 14.202 16.3462 14.3291 16.2618 14.5055L14.2361 18.8701C14.1676 19.0101 14.1438 19.1372 14.1649 19.2513C14.1913 19.3654 14.2493 19.4562 14.339 19.5236ZM16.0669 17.423L16.9502 15.3535H16.966L17.8562 17.423H16.0669Z"
        fill="currentColor"
        stroke="none"
      />
      <path d="M19.5266 9.10538V3.31582C19.5266 2.03683 18.4898 1 17.2108 1H3.31582C2.03683 1 1 2.03683 1 3.31582V18.3687C1 19.6477 2.03683 20.6845 3.31582 20.6845H10M5.63165 6.78956L6.78956 7.94747L9.10538 5.63165M12.5791 6.78956H17.2108M5.63165 13.737L6.78956 14.8949L9.10538 12.5791" />
    </svg>
  );
}

export default IconExams;
