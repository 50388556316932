import * as React from 'react';

function IconSimulated({ size, ...restProps }) {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth={2}
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={size ?? '1em'}
      height={size ?? '1em'}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M6.5 7.47059L7.875 8.76471L10.625 6.17647M14.75 7.47059H18.6691M6.5 15.2353L7.875 16.5294L10.625 13.9412M14.4631 15.2353H18.4735M3.75 1H20.25C21.7688 1 23 2.15879 23 3.58824V21C23 22.1046 22.1046 23 21 23H3.75C2.23122 23 1 21.8412 1 20.4118V3.58824C1 2.15879 2.23122 1 3.75 1Z" />
    </svg>
  );
}

export default IconSimulated;
