import React, { useState } from 'react';
import { VscGame } from 'react-icons/vsc';
import { FiAward, FiCheck } from 'react-icons/fi';
import Avatar from 'app/components/Avatar';
import Medal from 'app/components/Medal';
import trophy from './img/trophy.svg';
import branch from './img/branch.svg';
import MatterModal from './MatterModal';

export default function ChallengeResultBanner(props) {
  const { isStudent, selectedTab, studentchallenge, showTrophy } = props;
  const [showModal, setShowModal] = useState(false);

  const challenges = studentchallenge?.['accuracy-rate-highlights'];

  const topChallenges = challenges?.sort((a, b) => a['question-accuracy'] - b['question-accuracy'])?.slice(0, 2);

  return (
    <div className="result-challenge">
      <div className="result-challenge__col">
        {showTrophy && (
          <img
            className="result-challenge__trophy"
            src={trophy}
            alt="troféu"
          />
        )}

        {isStudent || selectedTab === 'resultados' ? <p className="result-challenge__hat">Estudante</p> : <p className="result-challenge__hat">Professor</p>}

        <div className="result-challenge__profile">
          <div className="result-challenge__avatar">
            <Avatar
              className="avatar--lg"
              src={studentchallenge?.user?.image}
              alt="Imagem do usuário"
            />
            <img
              className="result-challenge__branch"
              src={branch}
              alt="ramos"
            />
          </div>

          <div className="result-challenge__info">
            <h3 className="result-challenge__title">{studentchallenge?.user?.name}</h3>
            <div className="result-challenge__description">
              <p>
                <strong>{studentchallenge?.courses?.[0]}</strong>
              </p>
              <p>{studentchallenge?.institutions?.[0]}</p>
              <p>{studentchallenge?.regionals?.[0]}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="result-challenge__col">
        <p className="result-challenge__hat">Geral</p>

        <div className="result-challenge__kpi-wrapper">
          <div className="result-challenge__kpi">
            <Medal
              size="challenge"
              src="primary"
              value={<VscGame />}
            />
            {isStudent || selectedTab === 'resultados' ? <h4 className="result-challenge__kpi-title">Desafios</h4> : <h4 className="result-challenge__kpi-title">Desafios criados</h4>}
            <p className="result-challenge__kpi-value">{studentchallenge?.['challenge-attempt-count']}</p>
          </div>

          <div className="result-challenge__kpi">
            <Medal
              size="challenge"
              src="primary"
              value={<FiCheck />}
            />
            {isStudent || selectedTab === 'resultados' ? <h4 className="result-challenge__kpi-title">Acertos</h4> : <h4 className="result-challenge__kpi-title">Acertos dos estudantes</h4>}
            <p className="result-challenge__kpi-value">{studentchallenge?.['accuracy-rate'] + '%'}</p>
          </div>
        </div>
      </div>

      <div className="result-challenge__col">
        <p className="result-challenge__hat">Destaques</p>

        <div className="result-challenge__kpi-wrapper">
          {topChallenges?.map(item => (
            <div className="result-challenge__kpi">
              <Medal
                size="challenge"
                src={item['question-accuracy'] > 66 ? 'success' : item['question-accuracy'] <= 66 && item['question-accuracy'] > 33 ? 'warning' : 'warning'}
                value={<FiAward />}
              />
              <h4 className="result-challenge__kpi-title">{item.taxonomy}</h4>
              <p className={`result-challenge__kpi-value result-challenge__kpi-value--${item['question-accuracy'] > 66 ? 'success' : item['question-accuracy'] <= 66 && item['question-accuracy'] > 33 ? 'warning' : 'warning'}`}>
                {item['question-accuracy']}%
              </p>
            </div>
          ))}

          <div
            className="result-challenge__kpi result-challenge__kpi--more"
            onClick={() => setShowModal(true)}
          >
            <h4 className="result-challenge__kpi-title">Ver mais destaques</h4>
          </div>
        </div>
      </div>

      <MatterModal
        challenges={challenges}
        show={showModal}
        onClose={() => setShowModal(false)}
        isStudent={isStudent}
        selectedTab={selectedTab}
      />
    </div>
  );
}
