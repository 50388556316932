import React from 'react';
import { VscGame } from 'react-icons/vsc';
import timeIcon from './img/time-icon.svg';
import SpinWheel from './SpinWheel';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';

export default function Name() {
  const { currentChallenge } = useWeeklyChallenge();

  return (
    <section className="challenge-modal__section challenge-modal__section--name">
      <p className="challenge-modal__hat">{currentChallenge?.taxonomy?.name}</p>

      <div className="challenge-modal__name-section-header">
        <img
          className="avatar avatar--lg"
          src={currentChallenge?.image}
          alt={currentChallenge?.user?.name}
        />

        <h3 className="challenge-modal__name-section-title">{currentChallenge?.title}</h3>
        <p className="challenge-modal__name-section-subtitle">
          <VscGame className="challenge-modal__name-section-icon" /> Desafio
        </p>
      </div>

      {currentChallenge && <SpinWheel />}

      <p className="challenge-modal__warning fadeInLeft">
        <img
          src={timeIcon}
          alt="ícone tempo"
        />
        Tente fazer no menor tempo!
      </p>
    </section>
  );
}
