import React from 'react';
import { useFormik } from 'formik';
import CommentCard from './CommentCard';
import CommentForm from './CommentForm';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { useParams } from '@reach/router';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import EmptyState from 'app/components/EmptyState';

export default function LiveClassChat({ comments, setComments, hasMoreComments, setPage }) {
  const client = useClient();
  const params = useParams();

  const commentForm = useFormik({
    initialValues: {
      body: ''
    }
  });

  const handleDeleteComment = async commentId => {
    const { data, error } = await client.delete(`/live_classrooms/${params.liveClassId}/live_classroom_messages/${commentId}`);

    const newCommentData = comments.filter(item => item.id !== commentId);

    if (error) {
      console.log(error);
      toast.error(t('toast.errorDeleteComment'));
    } else {
      setComments(newCommentData);
      toast.success(t('toast.successDeleteComment'));
    }
  };

  return (
    <div className="chat-widget">
      <div
        id="scrollableDiv"
        className="chat-widget__body"
        style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          padding: 0
        }}
      >
        {!comments.length && (
          <EmptyState
            type="content"
            bgless
          />
        )}
        <InfiniteScroll
          dataLength={comments.length}
          next={() => setPage(prevPage => prevPage + 1)}
          hasMore={hasMoreComments}
          inverse={true}
          scrollableTarget="scrollableDiv"
        >
          {hasMoreComments && <Loader />}
          {comments.map(c => (
            <CommentCard
              key={c.id}
              comment={c}
              onDelete={handleDeleteComment}
            />
          ))}
        </InfiniteScroll>
      </div>

      <CommentForm commentForm={commentForm} />
    </div>
  );
}
